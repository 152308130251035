@font-face {
  font-family: 'Agrandir-bold';
  src: url('./fonts/uMove_Agrandir/PPAgrandir-NarrowBold.ttf');
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/Inter-VariableFont_slnt,wght.ttf');
}

@font-face {
  font-family: 'Bricolage_Grotesque';
  src: url('./fonts/Bricolage_Grotesque/BricolageGrotesque-VariableFont_opsz,wdth,wght.ttf');
}
