#navigation {
  .computer.large.screen.widescreen.only {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .desktop-menu {
    button {
      display: flex;
      max-width: 100%;
      box-sizing: border-box;
      flex: 1 1 auto;
      padding: 8px clamp(5px, 2vw, 30px);
      border-bottom: 0;
      margin-left: 3px;
      color: #000;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
      white-space: nowrap;

      &::before {
        display: none;
      }

      &:hover,
      &.active {
        border-radius: 50px;
        background: #f5f5f5;
      }
    }
  }

  .close {
    background-color: transparent;
  }

  .submenu.active {
    display: flex;
    justify-content: center;
    background: $light-grey;

    .submenu-inner {
      max-width: var(--layout-container-width);
    }
  }

  h2 {
    color: $blue;
    font-family: 'Agrandir-bold';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    display: inline-flex;
    align-items: center;
    &:after {
      display: inline-flex;
      content: '';
      width: 35px;
      height: 35px;
      margin-left: 5px;
      background-image: url('./Arrow_Blue.svg');
      background-size: contain;
      transition: 0.2s all;
    }
    &:hover {
    &:after {
      margin-left:8px;
    }
  }
  }
}

.header-wrapper .header .search-wrapper {
  flex: unset;
  @media screen and (max-width: $computer-width) {
    flex: 1 1 0;

    .search-button button {
      display: flex;
      width: 100%;
      align-items: center;
      border-radius: 25px;
      margin-right: 15px;
    }
  }
}

.search-wrapper.navigation-desktop .search .search-button {
  margin-top: 5px;

  button {
    display: flex;
    width: unset !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 50px !important;
  }

  svg {
    margin-left: 10px;
  }
}

.language-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px !important;
}

@media only screen and (min-width: $computer-width) {
  .logo-nav-wrapper {
    padding-top: 2px !important;
  }
}

.submenu-header:hover {
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color:$blue;  
}

.header-wrapper .header .logo-nav-wrapper {
  align-items: center !important;
}

.SiteButtonLinks {
  display: flex;
  height: 100%;

  @media (max-width: $extra-large-monitor-width) {
    .booking-calendarlink {
      padding: 10px 11px !important;

      .short-text {
        width: 30px;
        height: 30px;
        background-image: url('./calendar_today-white.svg');
        background-position: center;
        background-repeat: no-repeat;
      }

      &:hover {
        .short-text {
          background-image: url('./calendar_today-blue.svg');
        }
      }
    }

    .sport-pass {
      padding: 10px 11px !important;

      .short-text {
        width: 30px;
        height: 30px;
        background-image: url('./account-box-outline-white.svg');
        background-position: center;
        background-repeat: no-repeat;
      }

      &:hover {
        .short-text {
          background-image: url('./account-box-outline-blue.svg');
        }
      }
    }
  }
  @media (max-width: $computer-breakpoint) {
    .booking-calendarlink,
    .sport-pass {
      padding: 9px 10px !important;
    }
  }

  a {
    margin-left: 10px;
  }

  .first-link {
    margin-left: 0;
  }

  .short-text {
    display: none;
  }
  @media (max-width: $extra-large-monitor-width) {
    .full-text {
      display: none;
    }

    .short-text {
      display: inline;
    }
  }

  &.mobile {
    display: none;
  }
  @media only screen and (max-width: $computer-width) {
    display: none !important;

    &.mobile {
      display: flex !important;
    }
  }
}
@media only screen and (max-width: $computer-width) {
  .tools-wrapper {
    flex-direction: row !important;
    padding-top: 16px !important;
  }
}

.booking-calendarlink,
.sport-pass {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  padding: 10px 30px !important;
  border: 1px solid;
  border-color: transparent !important;
  border-radius: 25px !important;
  background-color: $blue !important;
  color: white !important;

  &:hover {
    border-color: $blue !important;
    background-color: white !important;
    color: $blue !important;
  }
}

.menu-drawer {
  .language-selector {
    display: none;
  }
}
