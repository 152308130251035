#page-document .block.usocial,
#page-edit .block.usocial {
  display: flex;
  justify-content: center;

  .usocial-headline {
    margin-bottom: 20px;
    color: $blue;
    font-family: Agrandir-bold;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.72px;
    line-height: 110%; /* 39.6px */
    text-align: center;
  }

  .socialmedia-icons {
    display: flex;
    justify-content: center;
    margin-top: 16px;

    .icon {
      width: 50px;
      height: 50px;
      padding: 1rem;
      border-radius: 50px;
      margin: 1rem;
      background-color: $blue;
      background-position: center;
      background-repeat: no-repeat;
    }

    .facebook {
      background-image: url('./Facebook.svg');
    }

    .instagram {
      background-image: url('./Instagram.svg');
    }

    .youtube {
      background-image: url('./Youtube.svg');
    }
  }
}

@each $name, $default-color in $theme-colors {
  $color: var(--#{$name}, $default-color);

  #page-document .block.usocial,
  #page-edit .block-editor-usocial {
    &.has--descriptionColor--#{$name} {
      .socialmedia-icons .icon {
        background-color: $color !important;
      }
    }
  }
}
