#page-document .block.usticker {
  border: 2px solid;
  border-left: 20px solid;
  background-color: white;

  .teaser-item.default {
    max-width: unset !important;
  }

  .content {
    padding-top: 35px;
    padding-right: 33px;
    padding-bottom: 37px;
    padding-left: 13px;

    .sticker-content {
      display: flex;
      flex-direction: row;

      @media only screen and (max-width: $computer-width) {
        display: flex;
        flex-direction: column;
      }
    }

    .Price {
      span {
        margin-right: 40px;
        color: $blue;
        font-family: 'Bricolage_Grotesque';
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: -2.56px;
        line-height: 110%; /* 70.4px */
      }
    }

    .lower-part {
      ul {
        padding-left: 0;
        list-style-type: none; /* Remove default bullets */
      }

      ul li {
        position: relative;
        padding-left: 20px; /* Space between the custom symbol and the text */
        color: $blue;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      ul li::before {
        position: absolute;
        left: 0;
        color: $blue;
        content: '\2713';
      }
    }

    .description2 {
      padding: 10px;
      margin-bottom: 10px;
      color: $blue;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .highlight {
      padding-bottom: 35px;
    }

    .higlight-headline {
      color: $blue;
      font-family: Inter;
      font-size: 24px;
      font-weight: bold;
    }
  }

  &:hover {
    background-color: #f5f5f5;
    transition: 0.12s all;
  }
}
@each $name, $default-color in $theme-colors {
  $color: var(--#{$name}, $default-color);

  #page-document,
  #page-edit {
    .block.usticker {
      &.has--descriptionColor--#{$name} {
        border-color: $color !important;
      }
    }
  }
}
