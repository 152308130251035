//Styles for UListing component

#page-document .UListing .items,
#page-edit .UListing .items {
  //background-color: red !important;
  .listing-item {
    padding-right: 10px;
    padding-bottom: 10px !important;

    padding-bottom: clamp(25px, 3vw, 40px) !important;
    padding-left: clamp(10px, 2vw, 13px);
    border: 2px solid transparent !important;
    border-bottom: none !important;
    border-bottom: 2px solid transparent !important;
    margin-bottom: clamp(20px, 3vw, 35px) !important;
    background-color: #f5f5f5;

    &:hover {
      border: 2px solid $blue !important;
      background-color: transparent;
      cursor: pointer;
      transition: all 0.3s ease 0s;
    }

    .listing-body p.date {
      margin-top: clamp(12px, 2vw, 20px);
      margin-bottom: 8px;
      color: #000;
      font-family: Inter;
      font-size: 14x;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &:last-child {
      margin-bottom: 0px !important;
    }
  }
}

#page-document .UListing,
#page-edit .UListing {
  @media only screen and (max-width: $tablet-breakpoint) {
    h2.headline {
      margin-left: 15px !important;
    }
  }
}

#page-document .block.gridBlock .ui .UListing div .items .listing-item,
#page-edit .block.gridBlock .ui .UListing div .items .listing-item {
  padding-bottom: clamp(25px, 3vw, 40px) !important;
  border-bottom: 2px solid transparent !important;
  margin-bottom: clamp(20px, 3vw, 35px) !important;

  &:hover {
    border: 2px solid $blue !important;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.3s ease 0s;
  }

  &:last-child {
    border-bottom: 2px solid transparent !important;

    &:hover {
      border: 2px solid $blue !important;
      background-color: transparent;
      cursor: pointer;
      transition: all 0.3s ease 0s;
    }
  }

  .listing-body p.date {
    margin-top: clamp(12px, 2vw, 20px);
    margin-bottom: 8px;
    color: #000;
    font-family: Inter;
    font-size: 14x;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &:last-child {
    margin-bottom: 0px !important;
  }
}
