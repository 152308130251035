// Breakpoints
// Mobile / Tablet portrait < 769 | Tablet landscape / Small desktop < 941 | Computer desktop < 1440 | Large Monitor > 1440
$largest-mobile-screen: 768px !default; // not finished in `-width` for historical reasons (SemanticUI naming)
$tablet-breakpoint: 769px !default;
$computer-width: 940px !default;
$computer-breakpoint: 941px !default;
$medium-monitor-width: 1200px !default;
$medium-monitor-breakpoint: 1201px !default;
$large-monitor-width: 1440px !default;
$large-monitor-breakpoint: 1441px !default;
$extra-large-monitor-width: 1480px !default;
$extra-large-monitor-breakpoint: 1481px !default;

$white: #fff;
$black: #000;

// Theme colors
$blue: #185bed !default;
$pink: #ffe3f3 !default;
$orange: #e46841 !default;
$light-green: #c5e851 !default;
$light-purple: #d7c8f5 !default;
$light-grey: #f5f5f5 !default;
$dark: #140f0f !default;
$transparent: transparent !default;

$white-arrow: url('./Arrow_White.svg');
$black-arrow: url('./Arrow_Black.svg');
$blue-arrow: url('./Arrow_Blue.svg');

//container changes

@media only screen and (min-width: $computer-width) {
  #page-document .blocks-group-wrapper > *,
  .header-wrapper .q.container.layout {
    max-width: clamp(500px, 93vw, var(--layout-container-width));
  }
}
@media only screen and (min-width: $medium-monitor-width) {
  #page-document .blocks-group-wrapper > *,
  .header-wrapper .q.container.layout {
    max-width: clamp(500px, 84vw, var(--layout-container-width));
  }
}

$theme-colors: (
  'highlight-custom-color-1': $blue,
  'highlight-custom-color-2': $pink,
  'highlight-custom-color-3': $orange,
  'highlight-custom-color-4': $light-green,
  'highlight-custom-color-5': $light-purple,
  'highlight-custom-color-6': $light-grey,
  'highlight-custom-color-7': $dark,
  'highlight-custom-color-8': $transparent,
);
$theme-text-colors: (
  'highlight-custom-color-1': $white,
  'highlight-custom-color-2': $blue,
  'highlight-custom-color-3': $black,
  'highlight-custom-color-4': $black,
  'highlight-custom-color-5': $black,
  'highlight-custom-color-6': $blue,
  'highlight-custom-color-7': $white,
  'highlight-custom-color-8': $blue,
);
$highlight-button-text-colors: (
  'highlight-custom-color-1': $white,
  'highlight-custom-color-2': $white,
  'highlight-custom-color-3': $black,
  'highlight-custom-color-4': $black,
  'highlight-custom-color-5': $black,
  'highlight-custom-color-6': $white,
  'highlight-custom-color-7': $white,
  'highlight-custom-color-8': $white,
);
$highlight-button-background-colors: (
  'highlight-custom-color-1': $transparent,
  'highlight-custom-color-2': $blue,
  'highlight-custom-color-3': $transparent,
  'highlight-custom-color-4': $transparent,
  'highlight-custom-color-5': $transparent,
  'highlight-custom-color-6': $blue,
  'highlight-custom-color-7': $transparent,
  'highlight-custom-color-8': $blue,
);
$highlight-button-hover-text-colors: (
  'highlight-custom-color-1': $blue,
  'highlight-custom-color-2': $blue,
  'highlight-custom-color-3': $black,
  'highlight-custom-color-4': $black,
  'highlight-custom-color-5': $black,
  'highlight-custom-color-6': $blue,
  'highlight-custom-color-7': $black,
  'highlight-custom-color-8': $blue,
);
$highlight-button-hover-background-colors: (
  'highlight-custom-color-1': $white,
  'highlight-custom-color-2': $white,
  'highlight-custom-color-3': $white,
  'highlight-custom-color-4': $white,
  'highlight-custom-color-5': $white,
  'highlight-custom-color-6': $white,
  'highlight-custom-color-7': $white,
  'highlight-custom-color-8': $transparent,
);
$highlight-button-arrow-color: (
  'highlight-custom-color-1': $white-arrow,
  'highlight-custom-color-2': $white-arrow,
  'highlight-custom-color-3': $black-arrow,
  'highlight-custom-color-4': $black-arrow,
  'highlight-custom-color-5': $black-arrow,
  'highlight-custom-color-6': $white-arrow,
  'highlight-custom-color-7': $white-arrow,
  'highlight-custom-color-8': $white-arrow,
);
$highlight-button-arrow-hover-color: (
  'highlight-custom-color-1': $blue-arrow,
  'highlight-custom-color-2': $blue-arrow,
  'highlight-custom-color-3': $black-arrow,
  'highlight-custom-color-4': $black-arrow,
  'highlight-custom-color-5': $black-arrow,
  'highlight-custom-color-6': $blue-arrow,
  'highlight-custom-color-7': $black-arrow,
  'highlight-custom-color-8': $blue-arrow,
);

#footer,
.footer {
  background-color: $pink !important;
}

//Fonts

#page-document,
.view-editview {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $blue;
    font-family: 'Agrandir-bold';
  }

  p,
  li,
  ul {
    color: $blue;
    font-family: Inter;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: -0.25%;
    line-height: 120%;

    a {
      color: $blue !important;
      font-family: Inter;
      font-size: 20px;
      font-weight: normal;
      font-weight: bold;
      letter-spacing: -0.25%;
      line-height: 120%;
      text-underline-position: under;

      &:hover {
        background-color: pink;
        text-decoration: none;
      }
    }
  }
}

.accordion {
  border-right: 0;
  border-left: 0;
}

//Button styles

.block.__button button {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  padding: 10px 30px !important;
  border-color: transparent !important;
  border-radius: 25px !important;
  background-color: $blue !important;
  color: white !important;

  &::after {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-image: $white-arrow;
    background-size: cover;
    content: '';
  }

  &:hover {
    border-color: $blue !important;
    background-color: white !important;
    color: $blue !important;

    &::after {
      background-image: $blue-arrow;
    }
  }

  a.external::after {
    background-image: none;
  }
}

.block.__button a.external::after {
  background-color: red;
  content: '' !important;
}

.header-wrapper .header .logo-nav-wrapper {
  align-items: center !important;
}

.SiteButtonLinks {
  height: 100%;

  @media (max-width: $extra-large-monitor-width) {
    .booking-calendarlink {
      padding: 10px 11px !important;

      .short-text {
        width: 30px;
        height: 30px;
        background-image: url('./calendar_today-white.svg');
        background-position: center;
        background-repeat: no-repeat;
      }

      &:hover {
        .short-text {
          background-image: url('./calendar_today-blue.svg');
        }
      }
    }

    .sport-pass {
      padding: 10px 12px !important;

      .short-text {
        width: 30px;
        height: 30px;
        background-image: url('./account-box-outline-white.svg');
        background-position: center;
        background-repeat: no-repeat;
      }

      &:hover {
        .short-text {
          background-image: url('./account-box-outline-blue.svg');
        }
      }
    }
  }
  @media (max-width: $computer-breakpoint) {
    .booking-calendarlink,
    .sport-pass {
      padding: 9px 10px !important;
    }
  }

  a {
    margin-left: 10px;
  }

  .first-link {
    margin-left: 0;
  }

  .short-text {
    display: none;
  }
  @media (max-width: $extra-large-monitor-width) {
    .full-text {
      display: none;
    }

    .short-text {
      display: inline;
    }
  }

  &.mobile {
    display: none;
  }
  @media only screen and (max-width: $computer-width) {
    display: none !important;

    &.mobile {
      display: flex !important;
    }
  }
}
@media only screen and (max-width: $computer-width) {
  .tools-wrapper {
    flex-direction: row !important;
    padding-top: 16px !important;
  }
}

.booking-calendarlink,
.sport-pass {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  padding: 10px 30px !important;
  border: 1px solid;
  border-color: transparent !important;
  border-radius: 25px !important;
  background-color: $blue !important;
  color: white !important;

  &:hover {
    border-color: $blue !important;
    background-color: white !important;
    color: $blue !important;
  }
}

//Highlight block customizations
#page-document .block.highlight,
#page-edit .block.highlight {
  padding-right: 10px;
  padding-left: 10px;

  .teaser-item.invert-layout {
    flex-direction: row-reverse;
  }

  .teaser-item {
    flex-direction: row;

    .highlight-image-wrapper {
      max-width: 50% !important;
      height: auto;
      aspect-ratio: 675/673;

      img {
        height: 100% !important;
        aspect-ratio: 675/673 !important;
      }
    }

    .highlight-description {
      width: 50%;
      padding-bottom: clamp(20px, 10vh, 100px);
      margin-top: 0 !important;

      .description {
        margin-top: clamp(20px, 5vw, 50px);
        margin-bottom: clamp(20px, 5vh, 50px);
      }

      .container {
        padding: clamp(20px, 5vw, 62px);
      }

      .title {
        margin-top: clamp(20px, 9vh, 100px);
      }

      .button {
        a {
          display: inline-flex !important;
          align-items: center;
          justify-content: center;
          padding: 10px 30px !important;
          border-radius: 25px !important;

          &::after {
            display: inline-block;
            width: 32px;
            height: 32px;
            background-size: cover;
            content: '';
          }
        }
      }
    }
  }

  @media only screen and (max-width: $computer-width) {
    padding-right: 0;
    padding-left: 0;

    .teaser-item {
      flex-direction: column !important;

      .highlight-image-wrapper {
        width: 100%;
        max-width: 100% !important;
        height: unset;
        aspect-ratio: unset;
      }

      .highlight-description {
        width: 100%;

        .container {
          padding: 30px;
        }

        .title h2 {
          font-size: 36px !important;
          letter-spacing: -0.5px !important;
          line-height: 40px !important;
        }

        .description p {
          margin-top: 25px;
          margin-bottom: 25px;
          font-size: 18px !important;
        }
      }
    }
  }
  @media only screen and (max-width: $largest-mobile-screen) {
    .teaser-item .highlight-description {
      .title {
        margin-top: clamp(20px, 7vh, 60px);
      }

      .container {
        padding: 5px;
      }

      padding-bottom: clamp(20px, 10vh, 80px);
    }
  }
}

.language-selector {
  a {
    color: $blue;
  }
}

.menu-drawer.menu-drawer-enter-done {
  background-color: white;
}

.menu-drawer ul.sections li,
.menu-drawer ul.sections li a {
  color: $blue;
  font-weight: regular;
}

@each $name, $default-color in $theme-colors {
  $color: var(--#{$name}, $default-color);
  $text-color: map-get($theme-text-colors, $name);
  $button-text-color: map-get($highlight-button-text-colors, $name);
  $button-background-color: map-get($highlight-button-background-colors, $name);
  $button-hover-text-color: map-get($highlight-button-hover-text-colors, $name);
  $button-hover-background-colors: map-get(
    $highlight-button-hover-background-colors,
    $name
  );
  $button-arrow-color: map-get($highlight-button-arrow-color, $name);
  $button-arrow-hover-color: map-get(
    $highlight-button-arrow-hover-color,
    $name
  );

  #page-document .block.highlight {
    &.has--descriptionColor--#{$name} {
      .highlight-description {
        background: $color;
        color: $text-color !important;

        h2,
        a,
        p {
          color: $text-color !important;
        }
      }

      .button a {
        background-color: $button-background-color !important;
        color: $button-text-color !important;

        &:hover {
          background-color: $button-hover-background-colors !important;
          color: $button-hover-text-color !important;

          &::after {
            background-image: $button-arrow-hover-color;
          }
        }

        &::after {
          background-image: $button-arrow-color;
        }
      }
    }
  }

  #page-edit,
  #page-add {
    .block-editor-highlight {
      &.has--descriptionColor--#{$name} .highlight-description {
        background: $color;
        color: $text-color !important;

        h2,
        a,
        p {
          color: $text-color !important;
        }

        .button a {
          background-color: $button-background-color !important;
          color: $button-text-color !important;

          &:hover {
            background-color: $button-hover-background-colors !important;
            color: $button-hover-text-color !important;

            &::after {
              background-image: $button-arrow-hover-color;
            }
          }

          &::after {
            background-image: $button-arrow-color;
          }
        }
      }
    }
  }

  .color-picker-widget {
    .button.#{$name},
    .button.#{$name}.active,
    .button.#{$name}:hover,
    .button.active.#{$name}:hover {
      background: $color;
    }
  }
}

// Styles for gridblock

.block.gridBlock.fullwidth {
  div.ui {
    max-width: 100% !important;
  }
}

.block.gridBlock .fullwidth {
  max-width: 100% !important;
}

.block.gridBlock.GridMobileSlider.four {
  @media only screen and (min-width: $largest-mobile-screen) {
    display: flex;

    .computergrid .column {
      flex-basis: 50%;
      margin-bottom: 20px;
    }
  }
}

.umoveteaser {
  position: relative;
  max-height: 1000px;
  aspect-ratio: 152/225;

  .teaser-item .image-wrapper {
    position: absolute;
    height: 100%;
    margin-bottom: 0 !important;

    img {
      height: 100% !important;
    }

    .overlay {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(20, 15, 15, 0.25); /* using rgba for transparency */
      mix-blend-mode: multiply;
    }

    .overlay-blur {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60%;
      backdrop-filter: blur(1px);
      background: linear-gradient(
        180deg,
        rgba(217, 217, 217, 0) 2.34%,
        rgba(6, 6, 6, 0.2) 20.57%,
        rgba(6, 6, 6, 0.6) 41%,
        rgba(3, 3, 3, 0.9) 99.76%
      );
    }
  }

  .content {
    z-index: 2;
    display: inline-flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;

    .highlight {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 8px 18px 10px 18px;
      border: 1px solid rgba(209, 213, 219, 0.1);
      border-radius: 50px;
      margin-top: 20px;
      -webkit-backdrop-filter: blur(15px) saturate(180%);
      backdrop-filter: blur(15px) saturate(180%);
      background-color: rgba(210, 210, 210, 0.5);

      .higlight-headline {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0 !important;
        color: white !important;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
      }
    }
  }

  &:hover {
    .overlay {
      background: rgba(
        20,
        15,
        15,
        0.05
      ) !important; /* using rgba for transparency */
      transition: background 0.2s ease 0s;
    }
  }

  .lower-part {
    margin-top: auto;

    p {
      border-radius: 5px;
      margin-bottom: 23px !important;
      background-color: rgba(34, 34, 34, 0.06);
      color: white !important;
      font-size: 18px !important;
    }

    button {
      padding: 12px 40px;
      border: none; /* Removes the border */
      border-radius: 50px;
      background-color: $blue;
      color: white;
      font-size: 14px;
      outline: none;

      &:hover {
        background-color: white;
        color: $blue;
        cursor: pointer;
        transition:
          background-color 0.1s ease,
          color 0.1s ease,
          background 0.1s ease;
      }
    }
  }
}

#page-document .Gridcontainer {
  .computergrid {
    display: flex;
  }

  .UISlider {
    display: none;

    .slick-dots {
      top: -45px;
      bottom: unset;
    }

    .slick-arrow {
      z-index: 100;

      &.slick-prev {
        top: unset;
        right: 110px;
        bottom: -50px;
        left: unset;
      }

      &.slick-next {
        top: unset;
        right: 50px;
        bottom: -50px;
      }

      &.slick-next::before,
      &.slick-prev::before {
        color: $blue;
        font-size: 55px;
      }
    }
  }

  &.MobileSlider {
    margin-bottom: 100px !important;

    .UISlider {
      display: none !important;
    }

    @media only screen and (max-width: $computer-width) {
      .computergrid {
        display: none;
      }

      .UISlider {
        display: block !important;

        .umoveteaser {
          min-height: 500px;
        }
      }
    }
  }
}

.view-viewview {
  .uvideo {
    display: flex;
    flex-direction: row;
    padding-right: 10px;
    padding-left: 10px;

    .information-container {
      display: flex;
      width: 36%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-right: 50px;

      p {
        margin-bottom: 50px;
        text-align: left;
      }

      button {
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        padding: 10px 30px !important;
        border-width: 1px !important;
        border-color: transparent !important;
        border-radius: 25px !important;
        background-color: $blue !important;
        color: white !important;

        &::after {
          display: inline-block;
          width: 32px;
          height: 32px;
          background-image: $white-arrow;
          background-size: cover;
          content: '';
        }

        &:hover {
          border-color: $blue !important;
          background-color: white !important;
          color: $blue !important;
          cursor: pointer;

          &::after {
            background-image: $blue-arrow;
          }
        }
      }
    }

    .video-inner {
      width: 64% !important;
      max-width: unset !important;
      margin: unset !important;
    }
    @media only screen and (max-width: $computer-width) {
      flex-direction: column-reverse !important;

      .information-container {
        width: 100% !important;
        margin-top: 30px;
        margin-bottom: 30px;
      }

      .video-inner {
        width: 100% !important;
      }
    }
  }
}

.view-editview {
  .uvideo {
    display: block;
  }

  .uvideoedit {
    display: flex !important;
    flex-direction: row !important;

    .video-inner {
      width: 64% !important;
      max-width: unset !important;
      height: auto;
      margin: unset !important;
    }

    .information-container {
      display: flex;
      width: 36%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-right: 50px;

      p {
        margin-bottom: 50px;
        text-align: left;
      }

      button {
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        padding: 10px 30px !important;
        border-width: 1px !important;
        border-color: transparent !important;
        border-radius: 25px !important;
        background-color: $blue !important;
        color: white !important;

        &::after {
          display: inline-block;
          width: 32px;
          height: 32px;
          background-image: $white-arrow;
          background-size: cover;
          content: '';
        }

        &:hover {
          border-color: $blue !important;
          background-color: white !important;
          color: $blue !important;
          cursor: pointer;

          &::after {
            background-image: $blue-arrow;
          }
        }
      }
    }
  }
}

blockquote {
  max-width: 639px !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-top: 80px;
  margin-bottom: 80px;
  color: #185bed;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.utextteaser {
  background-color: transparent !important;

  .teaser-item {
    display: flex !important;
    flex-direction: column !important;

    .content .highlight {
      margin-top: 25px;
      margin-bottom: 25px;

      .higlight-headline {
        color: #185bed;
        font-family: Agrandir-bold;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: -0.72px;
        line-height: 110%; /* 39.6px */
      }
    }

    .lower-part {
      p {
        margin-bottom: 50px !important;
      }

      button {
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        padding: 10px 30px !important;
        border-width: 1px !important;
        border-color: transparent !important;
        border-radius: 25px !important;
        background-color: $blue !important;
        color: white !important;

        &:hover {
          border-color: $blue !important;
          background-color: white !important;
          color: $blue !important;
          cursor: pointer;
        }
      }
    }
  }
}

@import 'navigation';
@import 'Uimage';
@import 'Usticker';
@import 'UListing';
@import 'Footer';
@import 'USocial';
@import 'SearchBlock';
@import 'ListingBlock';
