//Styles for Uimage component

#page-document .Uimage,
#page-edit .block-editor-uimage {
  figure {
    position: relative;

    img {
      aspect-ratio: 45/16;
      background-size: cover;
      mix-blend-mode: exclusion;
      object-fit: cover; /* Ensures the image covers the container */
      object-position: center; /* Centers the image in the container */
      opacity: 0.9;
    }

    .Caption-container {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transform: translate(-50%, -50%); /* Centers the element */

      button {
        padding: 12px 40px;
        border: none; /* Removes the border */
        border-radius: 50px;
        background-color: $blue;
        color: white;
        font-size: 14px;
        outline: none;

        &:hover {
          background-color: white !important;
          color: $blue;
          cursor: pointer;
          transition:
            background-color 0.1s ease,
            color 0.1s ease,
            background 0.1s ease;
        }
      }

      .title {
        color: #fff !important;
        font-family: 'Agrandir-bold';
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%; /* 70.4px */
        text-align: center;
      }
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(24, 91, 237, 0) 0%,
        rgba(24, 91, 237, 0.2) 100%
      );
      content: '';
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      mix-blend-mode: multiply;
    }

    &::before {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: black;
      content: '';
      opacity: 0.1;
    }
  }

  @media only screen and (max-width: $computer-width) {
    figure img {
      aspect-ratio: 43/48;
    }

    figure .Caption-container .title {
      font-size: 48px;
    }
  }
}

@each $name, $default-color in $theme-colors {
  $color: var(--#{$name}, $default-color);

  #page-document .block.Uimage,
  #page-edit .block-editor-uimage {
    &.has--descriptionColor--#{$name} {
      .Caption-container button {
        background-color: $color;
      }
    }
    &.has--descriptionColor--#{$name}.Appearance2,
    &.has--descriptionColor--#{$name} .block.Uimage.Appearance2 {
      .Caption-container .title {
        background-color: $color;
      }
    }
  }
}

#page-document .block.Uimage,
#page-edit .block-editor-uimage {
  &.has--descriptionColor--highlight-custom-color-2 {
    .Caption-container button {
      color: $blue;
    }
  }
}

#page-document .block.Uimage,
#page-edit .block.Uimage {
  &.Appearance2 .Caption-container {
    .title {
      padding: 27px 60px;
      border-radius: 100px;
      margin-bottom: 30px;
      font-size: clamp(24px, 3vw, 48px);
      transform: rotate(-5.513deg);
    }

    button {
      padding: 20px 30px;
      margin-bottom: 50px;
      font-size: clamp(12px, 2vw, 34px);
      transform: rotate(2.566deg);
    }
  }
}

$custom-colors: (
  2: $blue,
  4: $blue,
  5: $blue,
  6: $blue,
);

@each $key, $color in $custom-colors {
  #page-document .block.Uimage.Appearance2,
  #page-edit .block-editor-uimage {
    &.has--descriptionColor--highlight-custom-color-#{$key},
    &.has--descriptionColor--highlight-custom-color-#{$key}
      .block.Uimage.Appearance2 {
      .TitleAppearance2.Caption-container .title {
        color: $color !important;
      }
    }
  }

  #page-document .block.Uimage,
  #page-edit .block-editor-uimage {
    &.has--descriptionColor--highlight-custom-color-#{$key} {
      .Caption-container button {
        color: $color !important;
      }
    }
  }
}
