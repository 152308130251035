#footer .footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  span,
  a {
    color: $blue;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    line-height: 1.5;
    text-align: left;
  }

  a {
    margin-bottom: 5px;
    text-decoration: underline;
    text-underline-position: under;
  }

  .logo {
    height: 5rem;
    margin: 0;
  }

  h2 {
    margin-bottom: 0;
    margin-bottom: 15px;
    color: $blue;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .footer-section {
    display: flex;
    flex-direction: column;

    .footer-block {
      display: flex;
      height: 100px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 43px;
    }
  }

  a {
    color: $blue;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .lower-part {
    display: flex;
    flex-direction: row !important;
    align-items: flex-end !important;

    a {
      margin-right: 10px;
    }
  }

  .Lower-footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    grid-column: 4;
  }
  @media only screen and (max-width: $computer-width) {
    grid-template-columns: 1fr;

    .lower-part {
      justify-content: center !important;
    }
  }

  // Cookie consent link styles
  .lower-part {
    div:has(button.ui.blue.basic.button.link) {
      padding-bottom: 0px !important;
      margin-bottom: 3px !important;

      button {
        margin-right: 10px;
        margin-bottom: 0px;
        color: $blue !important;
        font-size: 14px;
        line-height: normal !important;
        text-underline-position: under;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
